import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import md5 from "js-md5";
import infiniteScroll from "vue-infinite-scroll";
import i18n from "./i18n/index";

Vue.use(ElementUI);
Vue.use(infiniteScroll);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");

Vue.prototype.$md5 = md5;
